import { CalendarIcon } from "@heroicons/react/24/solid"
import Headline from "components/general/headline"
import Layout from "components/layout"
import { graphql } from "gatsby"
import moment from "moment"
import * as React from "react"
import { ReactNode } from "react"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"

const ArticleTemplate = ({ data }) => {
  const article = data.strapiArticle

  // Markdown written in Strapi contains URL to file uploaded to Strapi
  // While importing to Gatsby, those images are copied.
  // Replace url in Markdown to the url of the image in Gatsby
  const preprocessArticle = (text: string, images: [ContentImage]): string => {
    images.forEach(image => {
      let markdownURL = `/uploads/${image.name}${image.ext}`
      text = text.replace(markdownURL, image.publicURL)
    })

    return text
  }

  const getDate = (article): ReactNode => {
    let articleCreated = article.createdAt
    if (article.imported?.created !== undefined) {
      // found a date from an article importen from Drupal
      articleCreated = article.imported.created
    }

    return (
      <div className="flex items-center">
        <CalendarIcon
          className="mr-1.5  h-5 w-5 flex-shrink-0"
          aria-hidden="true"
        />
        <span>{moment(articleCreated).format("DD.MM.YYYY HH:mm")}</span>
      </div>
    )
  }

  const seo = {
    metaTitle: article.seo?.metaTitle ?? article.title,
    metaDescription: article.seo?.metaDescription ?? "",
  }

  return (
    <Layout seo={seo}>
      <div className="main-box">
        <Headline headline={article.title} subHeadline={getDate(article)} />

        <Markdown
          children={preprocessArticle(article.content, article.content_images)}
          remarkPlugins={[remarkGfm]}
          className="article mt-2 text-justify leading-6"
        />
      </div>
    </Layout>
  )
}

export default ArticleTemplate

interface ContentImage {
  publicURL: string
  name: string
  ext: string
}

export const query = graphql`
  query ArticleTemplate($id: String!) {
    strapiArticle(id: { eq: $id }) {
      id
      createdAt
      title
      content
      imported {
        created
      }
      content_images {
        name
        publicURL
        ext
      }
      seo {
        metaTitle
        metaDescription
        keywords
      }
    }
  }
`
